<template>
    <div :class="containerClass" @click="onDocumentClick($event)">
        <AppTopBar
            :topbarTheme="topbarTheme"
            :activeTopbarItem="activeTopbarItem"
            @menu-button-click="onMenuButtonClick($event)"
            @right-panel-click="onRightPanelButtonClick"
            @topbar-item-click="onTopbarItemClick"
            @search-click="onSearchClick"
        ></AppTopBar>

        <div class="menu-wrapper fadeinleft animation-duration-500" @click="onMenuClick($event)">
            <AppMenu :model="fullMenu" :active="menuActive" :mobileMenuActive="staticMenuMobileActive" :menuMode="menuMode" @menu-click="onMenuClick" @menuitem-click="onMenuItemClick" @root-menuitem-click="onRootMenuItemClick"></AppMenu>
        </div>

        <div class="layout-main fadeindown animation-duration-1000">
            <AppBreadcrumb></AppBreadcrumb>

            <div class="layout-content">
                <router-view :key="$route.path" />
            </div>

            <AppFooter></AppFooter>
        </div>

        <div v-if="staticMenuMobileActive" class="layout-mask modal-in"></div>
    </div>
    <Toast />
</template>

<script>
import { useNewStore } from '@/store';
import { mapState, mapActions } from 'pinia';
import EventBus from '@/event-bus';
import AppTopBar from '@/AppTopbar.vue';
import AppMenu from '@/AppMenu.vue';
import AppFooter from '@/AppFooter.vue';
import AppBreadcrumb from '@/AppBreadcrumb.vue';
import { firedb } from '@/firebase';
import { collection, query, where, onSnapshot } from 'firebase/firestore';

export default {
    data() {
        return {
            theme: 'denim',
            topbarTheme: 'dark',
            menuTheme: 'dark',
            layoutMode: 'dark',
            menuMode: 'static',
            activeTopbarItem: null,
            menuActive: false,
            overlayMenuActive: false,
            staticMenuDesktopInactive: false,
            staticMenuMobileActive: false,
            menuClick: false,
            searchActive: false,
            searchClick: false,
            userMenuClick: false,
            topbarMenuActive: false,
            rightPanelClick: false,
            rightPanelActive: false,
            configActive: false,
            configClick: false,
            menuOptions: {
                twitter: {
                    label: 'Organic X Data',
                    icon_code: 'twitter',
                    img_src: null,
                    page: 'x',
                    order: 0,
                },
                facebook: {
                    label: 'Organic Facebook Data',
                    icon_code: 'facebook',
                    img_src: null,
                    page: 'facebook',
                    order: 1,
                },
                tiktok: {
                    label: 'Organic Tiktok Data',
                    icon_code: 'tiktok',
                    img_src: null,
                    page: 'tiktok',
                    order: 2,
                },
                instagram: {
                    label: 'Organic Instagram Data',
                    icon_code: 'instagram',
                    img_src: null,
                    page: 'instagram',
                    order: 3,
                },
                email: {
                    label: 'Competitive Email',
                    icon_code: 'envelope',
                    img_src: null,
                    page: 'email',
                    order: 4,
                },
                news: {
                    label: 'News Data',
                    icon_code: null,
                    img_src: 'icon-news.png',
                    page: 'news',
                    order: 5,
                },
                actblue: {
                    label: 'ActBlue',
                    icon_code: null,
                    img_src: 'icon-actblue.png',
                    page: 'actblue',
                    order: 6,
                },
                ngp: {
                    label: 'NGP Data',
                    icon_code: null,
                    img_src: 'icon-ngp.png',
                    page: 'ngp',
                    order: 7,
                },
                mailchimp: {
                    label: 'Mailchimp Data',
                    icon_code: null,
                    img_src: 'icon-mailchimp.png',
                    page: 'mailchimp',
                    order: 8,
                },
                google_postmaster: {
                    label: 'Postmaster Data',
                    icon_code: null,
                    img_src: 'icon-postmaster.png',
                    page: 'google-postmaster',
                    order: 9,
                },
                google_analytics: {
                    label: 'Website Analytics',
                    icon_code: null,
                    img_src: 'icon-googleanalytics.png',
                    page: 'google-analytics',
                    order: 10,
                },
                accurate_append: {
                    label: 'Data Appending',
                    icon_code: null,
                    img_src: 'icon-accurate-append.jpeg',
                    page: 'data-appending',
                    order: 11,
                },
                scan_meta: {
                    label: 'Meta Political Ads',
                    icon_code: null,
                    img_src: 'icon-scan.png',
                    page: 'scan-meta',
                    order: 12,
                },
                scan_google: {
                    label: 'Google Political Ads',
                    icon_code: null,
                    img_src: 'icon-scan.png',
                    page: 'scan-google',
                    order: 13,
                },
                scan_snap: {
                    label: 'Snap Political Ads',
                    icon_code: null,
                    img_src: 'icon-scan.png',
                    page: 'scan-snap',
                    order: 14,
                },
                scan_x: {
                    label: 'X Political Ads',
                    icon_code: null,
                    img_src: 'icon-scan.png',
                    page: 'scan-x',
                    order: 15,
                },
                scan_roku: {
                    label: 'Roku Political Ads',
                    icon_code: null,
                    img_src: 'icon-scan.png',
                    page: 'scan-roku',
                    order: 15,
                },
                scan_api: {
                    label: 'SCAN API Endpoint',
                    icon_code: null,
                    img_src: 'icon-scan.png',
                    page: 'scan-api',
                    order: 16,
                },
                ad_impact_ott_ctv: {
                    label: 'OTT/CTV Ads',
                    icon_code: null,
                    img_src: 'icon-ad-impact.png',
                    page: 'ad-impact-ott-ctv',
                    order: 17,
                },
                ad_impact_radio: {
                    label: 'Radio Ads',
                    icon_code: null,
                    img_src: 'icon-ad-impact.png',
                    page: 'ad-impact-radio',
                    order: 18,
                },
                ad_impact_linear_tv: {
                    label: 'Linear TV Ads',
                    icon_code: null,
                    img_src: 'icon-ad-impact.png',
                    page: 'ad-impact-linear-tv',
                    order: 19,
                },
                tally: {
                    label: 'Tally Ballot Returns',
                    icon_code: null,
                    img_src: 'icon-tally.png',
                    page: 'tally-ballot-returns',
                    order: 20,
                },
            },
            menu: [
                {
                    label: 'Quick Access',
                    items: [
                        { label: 'Mixed Dashboard', icon: 'pi pi-fw pi-home', to: '/' },
                        { label: 'Manage Sources', icon: 'pi pi-fw pi-bolt', to: '/manage' },
                    ],
                },
            ],
            active_subscriptions: [],
        };
    },
    computed: {
        ...mapState(useNewStore, ['orgDoc']),
        containerClass() {
            return [
                'layout-wrapper',
                {
                    'layout-overlay': this.menuMode === 'overlay',
                    'layout-static': this.menuMode === 'static',
                    'layout-horizontal': this.menuMode === 'horizontal',
                    'layout-overlay-active': this.overlayMenuActive,
                    'layout-mobile-active': this.staticMenuMobileActive,
                    'layout-static-active': !this.staticMenuDesktopInactive && this.menuMode === 'static',
                    'layout-rightpanel-active': this.rightPanelActive,
                    'layout-rtl': this.$appState.isRTL,
                    'p-input-filled': this.$primevue.config.inputStyle === 'filled',
                    'p-ripple-disabled': !this.$primevue.config.ripple,
                },
                'layout-menu-' + this.menuTheme + ' layout-topbar-' + this.topbarTheme,
            ];
        },
        customMenu() {
            if (this.orgDoc?.sidebar_order !== undefined) {
                let customMenu = {
                    label: 'Sources',
                    items: [],
                };
                for (let subscription_type of this.orgDoc.sidebar_order.filter((x) => this.active_subscriptions.includes(x))) {
                    if (this.menuOptions[subscription_type].icon_code !== null) {
                        // Show icon next to menu option
                        customMenu.items.push({
                            label: this.menuOptions[subscription_type].label,
                            icon: 'pi pi-fw pi-' + this.menuOptions[subscription_type].icon_code,
                            to: { name: this.menuOptions[subscription_type].page },
                            order: this.orgDoc?.sidebar_order?.indexOf(subscription_type) ? this.orgDoc?.sidebar_order?.indexOf(subscription_type) : this.menuOptions[subscription_type].order + 100,
                            subscription_type: subscription_type,
                        });
                    } else {
                        // Show image next to menu option
                        customMenu.items.push({
                            label: this.menuOptions[subscription_type].label,
                            icon: 'pi pi-fw pi-custom',
                            icon_url: '/layout/images/' + this.menuOptions[subscription_type].img_src,
                            to: { name: this.menuOptions[subscription_type].page },
                            order: this.orgDoc?.sidebar_order?.indexOf(subscription_type) ? this.orgDoc?.sidebar_order?.indexOf(subscription_type) : this.menuOptions[subscription_type].order + 100,
                            subscription_type: subscription_type,
                        });
                    }
                }
                if (customMenu.items.length < 2) {
                    customMenu.items.push({ label: 'Add new...', icon: 'pi pi-fw pi-plus', to: { name: 'manage' } });
                }
                return [customMenu];
            } else {
                return [
                    {
                        label: 'Sources',
                        items: [
                            {
                                label: 'Sources',
                                items: [{ label: 'Add new...', icon: 'pi pi-fw pi-plus', to: { name: 'manage' } }],
                            },
                        ],
                    },
                ];
            }
        },
        partnerMenu() {
            if (this.orgDoc?.uplift_partner) {
                let itemlist = [];
                if (this.orgDoc.uplift_partnerData?.facebook_data?.length > 0) {
                    itemlist = itemlist.concat({ label: 'Meta Performance', icon: 'pi pi-fw pi-facebook', to: { name: 'partner-meta' } });
                }
                if (this.orgDoc.uplift_partnerData?.google_ads?.length > 0) {
                    itemlist = itemlist.concat({ label: 'Search Performance', icon: 'pi pi-fw pi-google', to: { name: 'partner-google' } });
                }
                if (this.orgDoc.uplift_partnerData?.youtube_ads?.length > 0) {
                    itemlist = itemlist.concat({ label: 'Youtube Performance', icon: 'pi pi-fw pi-youtube', to: { name: 'partner-youtube' } });
                }
                if (this.orgDoc.uplift_partnerData?.hulu_ads?.length > 0) {
                    itemlist = itemlist.concat({ label: 'Hulu Performance', icon: 'pi pi-fw pi-custom', icon_url: '/layout/images/icon-hulu.png', to: { name: 'partner-hulu' } });
                }
                if (this.orgDoc.uplift_partnerData?.programmatic_data?.length > 0) {
                    itemlist = itemlist.concat({ label: 'Programm. Performance', icon: 'pi pi-fw pi-video', to: { name: 'partner-programmatic' } });
                }
                if (this.orgDoc.uplift_partnerData?.domain_performance?.length > 0) {
                    itemlist = itemlist.concat({ label: 'Domain Performance', icon: 'pi pi-fw pi-globe', to: { name: 'partner-domain' } });
                }
                if (this.orgDoc.uplift_partnerData?.programmatic_geo_data?.length > 0) {
                    itemlist = itemlist.concat({ label: 'Geo Performance', icon: 'pi pi-fw pi-map-marker', to: { name: 'partner-geo' } });
                }
                let newMenu = [
                    {
                        label: 'Ads Data',
                        items: itemlist,
                    },
                    {
                        label: 'Resources',
                        items: [
                            { label: 'Term Definitions', icon: 'pi pi-fw pi-book', to: { name: 'definitions' } },
                            { label: 'Site Instructions', icon: 'pi pi-fw pi-info-circle', to: { name: 'instructions' } },
                        ],
                    },
                ];
                return newMenu;
            } else {
                return [
                    {
                        label: 'Resources',
                        items: [
                            { label: 'Term Definitions', icon: 'pi pi-fw pi-book', to: { name: 'definitions' } },
                            { label: 'Site Instructions', icon: 'pi pi-fw pi-info-circle', to: { name: 'instructions' } },
                        ],
                    },
                ];
            }
        },
        fullMenu() {
            return this.menu.concat(this.customMenu, this.partnerMenu);
        },
    },
    watch: {
        $route() {
            this.menuActive = false;
            this.$toast.removeAllGroups();
        },
        'orgDoc.id': function () {
            this.fetchActive();
        },
    },
    methods: {
        ...mapActions(useNewStore, ['updateOrgDoc']),
        async fetchActive() {
            if (!this.orgDoc?.id) {
                setTimeout(() => {
                    this.fetchActive();
                }, '2000');
            } else {
                const q = query(collection(firedb, 'subscriptions'), where('client_id', '==', this.orgDoc.id));
                onSnapshot(q, (querySnapshot) => {
                    this.active_subscriptions = [];
                    querySnapshot.forEach((doc) => {
                        let docData = doc.data();
                        if ((docData?.status == 'active' || docData?.status === 'processing') && docData?.subscription_type !== undefined && this.menuOptions[docData.subscription_type] !== undefined) {
                            if (!this.active_subscriptions?.includes(docData.subscription_type)) {
                                this.active_subscriptions.push(docData.subscription_type);
                            }
                            if (!this.orgDoc?.sidebar_order?.includes(docData.subscription_type)) {
                                // Ensure that all active subscriptions are included even if they haven't been ordered
                                this.orgDoc?.sidebar_order?.push(docData.subscription_type);
                            }
                        }
                    });
                });
            }
        },
        onDocumentClick() {
            if (!this.searchClick) {
                this.searchActive = false;
            }

            if (!this.topbarItemClick) {
                this.activeTopbarItem = null;
                this.topbarMenuActive = false;
            }

            if (!this.userMenuClick) {
                this.topbarMenuActive = false;
            }

            if (!this.rightPanelClick) {
                this.rightPanelActive = false;
            }

            if (!this.menuClick) {
                if (this.isHorizontal()) {
                    EventBus.emit('reset-active-index');
                    this.menuActive = false;
                }

                if (this.overlayMenuActive || this.staticMenuMobileActive) {
                    this.hideOverlayMenu();
                }

                this.unblockBodyScroll();
            }

            if (this.configActive && !this.configClick) {
                this.configActive = false;
            }

            this.searchClick = false;
            this.configClick = false;
            this.topbarItemClick = false;
            this.rightPanelClick = false;
            this.menuClick = false;
        },
        onMenuClick() {
            this.menuClick = true;
        },
        onMenuButtonClick(event) {
            this.rotateMenuButton = !this.rotateMenuButton;
            this.topbarMenuActive = false;
            this.menuClick = true;

            if (this.isOverlay() && !this.isMobile()) {
                this.overlayMenuActive = !this.overlayMenuActive;
            }

            if (this.isDesktop()) {
                this.staticMenuDesktopInactive = !this.staticMenuDesktopInactive;
            } else {
                this.staticMenuMobileActive = !this.staticMenuMobileActive;
                if (this.staticMenuMobileActive) {
                    this.blockBodyScroll();
                } else {
                    this.unblockBodyScroll();
                }
            }

            event.preventDefault();
        },
        onMenuItemClick(event) {
            if (!event.item.items) {
                EventBus.emit('reset-active-index');
                this.hideOverlayMenu();
            }
            if (!event.item.items && this.isHorizontal()) {
                this.menuActive = false;
            }
        },
        onRootMenuItemClick() {
            this.menuActive = !this.menuActive;
        },
        onRightPanelClick() {
            this.rightPanelClick = true;
        },
        onRightPanelButtonClick(event) {
            this.rightPanelClick = true;
            this.rightPanelActive = !this.rightPanelActive;
            event.preventDefault();
        },
        onTopbarItemClick(event) {
            this.topbarItemClick = true;

            if (this.activeTopbarItem === event.item) {
                this.activeTopbarItem = null;
            } else {
                this.activeTopbarItem = event.item;
            }

            if (event.item === 'search') {
                this.searchActive = !this.searchActive;
                this.searchClick = !this.searchClick;
            }

            event.originalEvent.preventDefault();
        },
        onSearchClick(event) {
            this.searchClick = true;
            event.stopPropagation();
        },
        onConfigClick() {
            this.configClick = true;
        },
        onConfigButtonClick() {
            this.configActive = !this.configActive;
            this.configClick = true;
        },
        hideOverlayMenu() {
            this.overlayMenuActive = false;
            this.staticMenuMobileActive = false;
            this.unblockBodyScroll();
        },
        blockBodyScroll() {
            if (document.body.classList) {
                document.body.classList.add('blocked-scroll');
            } else {
                document.body.className += ' blocked-scroll';
            }
        },
        unblockBodyScroll() {
            if (document.body.classList) {
                document.body.classList.remove('blocked-scroll');
            } else {
                document.body.className = document.body.className.replace(new RegExp('(^|\\b)' + 'blocked-scroll'.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
            }
        },
        isTablet() {
            const width = window.innerWidth;
            return width <= 1024 && width > 640;
        },
        isDesktop() {
            return window.innerWidth > 992;
        },
        isMobile() {
            return window.innerWidth <= 640;
        },
        isOverlay() {
            return this.menuMode === 'overlay';
        },
        isStatic() {
            return this.menuMode === 'static';
        },
        isHorizontal() {
            return this.menuMode === 'horizontal';
        },
    },
    components: {
        AppTopBar,
        AppMenu,
        AppFooter,
        AppBreadcrumb,
    },
    mounted() {
        this.fetchActive();
    },
};
</script>

<style lang="scss">
@import 'App.scss';
</style>
