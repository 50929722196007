<template>
    <div v-if="membership.length > 0" class="grid text-center">
        <div v-for="org of membership" :key="org.id" class="col-12 lg:col-4">
            <div class="card" @click="setCurrentOrg($event, org.id)" style="opacity: 1; cursor: pointer; height: 150px; overflow-wrap: break-word">
                <h5>{{ org.name ? org.name : 'Unnamed Organization' }}</h5>
                <div class="text-center">
                    <div class="p-image p-component p-image-preview-container">
                        <img :src="org.picture ? org.picture : org.org_picture ? org.org_picture : ''" style="max-width: 100%; max-height: 100px" />
                        <div class="p-image-preview-indicator"><i class="p-image-preview-icon pi pi-sign-in"></i></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { useNewStore } from '@/store';
import { mapState, mapActions } from 'pinia';
export default {
    data() {
        return {
            accessKey: null,
            inviteButton: false,
        };
    },
    emits: ['closeSwitcher'],
    methods: {
        ...mapActions(useNewStore, ['updateUserDoc', 'getMemberships']),
        setCurrentOrg(event, id) {
            let payload = { current_client: id };
            this.updateUserDoc(payload);
            this.$router.push({ name: 'dashboard' });
            this.$emit('closeSwitcher');
            setTimeout(this.fetchOrgDoc, 500);
        },
    },
    computed: {
        ...mapState(useNewStore, ['user', 'userDoc', 'membership', 'fetchOrgDoc']),
        orglist() {
            return this.userDoc ? this.userDoc.clients : [];
        },
        query() {
            return this.$route.query;
        },
    },
    mounted() {
        this.getMemberships();
    },
};
</script>
