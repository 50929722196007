<template>
    <router-view v-if="userDoc || excepted_pages.includes($route.name)"></router-view>
    <div v-else class="loader">
        <div class="loader-inner">
            <div class="loader-line-wrap">
                <div class="loader-line"></div>
            </div>
            <div class="loader-line-wrap">
                <div class="loader-line"></div>
            </div>
            <div class="loader-line-wrap">
                <div class="loader-line"></div>
            </div>
            <div class="loader-line-wrap">
                <div class="loader-line"></div>
            </div>
            <div class="loader-line-wrap">
                <div class="loader-line"></div>
            </div>
        </div>
    </div>
</template>
<script>
import { useNewStore } from '@/store';
import { mapState } from 'pinia';

export default {
    data() {
        return {
            excepted_pages: ['login', 'email-login', 'error', 'notfound', 'access'], // These pages aren't expected to have a userDoc, but still need to load without one
        };
    },
    computed: {
        ...mapState(useNewStore, ['userDoc']),
    },
};
</script>
