<template>
    <div class="layout-topbar">
        <div class="layout-topbar-wrapper">
            <div class="layout-topbar-left">
                <router-link to="/">
                    <div class="layout-topbar-logo" id="logolink" style="cursor: pointer; outline: none">
                        <img id="app-logo" alt="Ascend Digital Analytics" :src="orgDoc?.picture ? orgDoc.picture : '/Ascend_Straight_White.png'" />
                    </div>
                </router-link>
            </div>

            <div class="layout-topbar-right">
                <a class="menu-button" href="#" @click="$emit('menu-button-click', $event)">
                    <i class="pi pi-bars"></i>
                </a>

                <ul class="layout-topbar-actions">
                    <li class="topbar-item">
                        <router-link :to="{ name: 'notifications' }">
                            <i class="pi pi-bell text-color-secondary" style="font-size: 1.5rem"></i>
                        </router-link>
                    </li>
                    <li class="topbar-item user-profile" :class="{ 'active-topmenuitem': activeTopbarItem === 'profile' }">
                        <a href="#" @click="onTopbarItemClick($event, 'profile')">
                            <img class="profile-image border-circle" :src="userDoc?.user_avatar ? userDoc.user_avatar : user?.photoURL ? user.photoURL : 'layout/images/avatar-profile.png'" />
                            <div class="profile-info">
                                <h6>{{ userDoc?.display_name ? userDoc.display_name : user?.displayName ? user.displayName : 'Ascend User' }}</h6>
                                <span>{{ orgDoc?.name ? orgDoc.name : 'No organization' }}</span>
                            </div>
                        </a>

                        <ul class="fadeInDown">
                            <li class="layout-submenu-header">
                                <img class="profile-image border-circle" :src="userDoc?.user_avatar ? userDoc.user_avatar : user?.photoURL ? user.photoURL : 'layout/images/avatar-profile.png'" />
                                <div class="profile-info">
                                    <h6>{{ userDoc?.display_name ? userDoc.display_name : user?.displayName ? user.displayName : 'Ascend User' }}</h6>
                                    <span>{{ orgDoc?.name ? orgDoc.name : '' }}</span>
                                </div>
                            </li>
                            <li role="menuitem">
                                <a @click="openSwitcher">
                                    <i class="pi pi-sync"></i>
                                    <h6>Switch Organization</h6>
                                </a>
                            </li>
                            <li role="menuitem">
                                <a @click="$router.push({ path: '/manage' })">
                                    <i class="pi pi-compass"></i>
                                    <h6>Manage Data Sources</h6>
                                </a>
                            </li>
                            <li role="menuitem">
                                <a @click="$router.push({ path: '/settings' })">
                                    <i class="pi pi-cog"></i>
                                    <h6>Settings</h6>
                                </a>
                            </li>
                            <li v-if="isStaff" role="menuitem">
                                <a @click="$router.push({ path: '/admin' })">
                                    <i class="pi pi-users"></i>
                                    <h6>Manage Clients</h6>
                                    <Tag value="Admin" severity="warning" class="ml-2"></Tag>
                                </a>
                            </li>
                            <li role="menuitem">
                                <a @click="userSignOut">
                                    <i class="pi pi-power-off"></i>
                                    <h6>Sign Out</h6>
                                </a>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <Dialog header="Organizations" v-model:visible="displaySwitcher" class="w-full md:w-6">
        <UserOrgs @closeSwitcher="closeSwitcher"></UserOrgs>
        <template #footer>
            <Button label="Close" icon="pi pi-times" @click="closeSwitcher" class="p-button-text" />
        </template>
    </Dialog>
</template>

<script>
import { useNewStore } from '@/store';
import { mapActions, mapState } from 'pinia';
import UserOrgs from '@/components/UserOrgs.vue';
import { fireauth } from '@/firebase';
import { signOut } from 'firebase/auth';

export default {
    name: 'AppTopbar',
    emits: ['menu-button-click', 'right-panel-click', 'topbar-item-click', 'search-click'],
    props: {
        topbarTheme: String,
        activeTopbarItem: String,
    },
    components: {
        UserOrgs: UserOrgs,
    },
    data() {
        return {
            displaySwitcher: false,
        };
    },
    methods: {
        ...mapActions(useNewStore, ['getMemberships', 'fetchOrgDoc', 'fetchAllOrgs']),
        goLogin() {
            this.$router.push({ path: '/login' });
        },
        userSignOut() {
            signOut(fireauth)
                .then(() => {
                    setTimeout(() => {
                        this.goLogin();
                    }, 500);
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        onTopbarItemClick(event, item) {
            this.$emit('topbar-item-click', { originalEvent: event, item: item });
            event.preventDefault();
        },
        onTopbarSubItemClick(event) {
            event.preventDefault();
        },
        openSwitcher() {
            this.displaySwitcher = true;
        },
        closeSwitcher() {
            this.displaySwitcher = false;
            this.fetchOrgDoc();
        },
    },
    computed: {
        ...mapState(useNewStore, ['user', 'userDoc', 'orgDoc', 'membership', 'isStaff']),
    },
    mounted() {
        this.fetchOrgDoc();
        this.getMemberships();
    },
};
</script>
